import { Alert, Snackbar } from "@mui/material";

function ResultToast({ message, setMessage, type }) {
    const handleClose = async () => {
        setMessage(null);
    };

    return (
        <Snackbar
            open={(message) ? true : false}
            onClose={handleClose}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <Alert onClose={handleClose} severity={type} variant="filled">
                {message}
            </Alert>
        </Snackbar>
    );
}

export default ResultToast;