import { useContext, useEffect, useState } from "react";
import { BACKEND_URL, userContext } from "../Context";
import Navbar from "../components/Navigation/Navbar";
import "./Users.css";
import UsersList from "../components/UsersList";
import { Button, Typography } from "@mui/material";

function Recruits() {
    const [ recruits, setRecruits ] = useState([]);
    
    useEffect(() => {
        const loadUserList = async () => {
            const req = await fetch(`${BACKEND_URL}/api/user/all`);
            const users = await req.json();
            const list = [];
            for(const user of users) {
                if(user.rank == 'recruit' || user.rank == 'recruit_lasat')
                    list.push(user);
            }
            setRecruits(list);
        };

        loadUserList();
    }, []);

    return <>
        <Navbar />
        <div id="users-page">
            <Typography variant="h4">Recruits</Typography>
            <UsersList 
                title="Recruits" 
                list={recruits}
                showDeptOptions
            />
        </div>
    </>;
}

export default Recruits;