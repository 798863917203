import { Checkbox, FormControl, FormControlLabel, TextField } from "@mui/material";
import FormModal from "./FormModal";
import { useContext, useEffect, useState } from "react";
import { BACKEND_URL, userContext } from "../../Context";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { PhaseApi } from "../../Utils";
import { siteContext } from "../Utils/SiteContext";

function NewPhaseForm({ active, setActive }) {
    const { successToast, errorToast } = useContext(siteContext);

    const [ name, setName ] = useState('');
    const [ startDate, setStartDate ] = useState(dayjs());
    const [ endDate, setEndDate ] = useState(dayjs());
    const [ canRegister, setCanRegister ] = useState(false);
    const [ canScheduleInterview, setCanScheduleInterview ] = useState(false);
    const [ canSelectOptions, setCanSelectOptions ] = useState(false);
    const [ canSeeTeams, setCanSeeTeams ] = useState(false);
    const [ canUploadHomework, setCanUploadHomework ] = useState(false);
    const [ canSelectFinalOptions, setCanSelectFinalOptions ] = useState(false);
    const [ questionsString, setQuestionsString ] = useState('');

    const createPhase = async () => {
        const req = await PhaseApi.createPhase(
            name, 
            startDate, 
            endDate, 
            questionsString, 
            canRegister, 
            canScheduleInterview
        );

        if(req.status === 200) {
            successToast(`Phase "${name}" created.`);
        }
        else {
            errorToast(`Failed to create phase "${name}".`);
            console.error(await req.text());
        }

        setActive(false);
    };

    return (
        <FormModal name="newPhase" title="Create new phase" active={active} setActive={setActive} onSubmit={createPhase}>
            <FormControl fullWidth style={{ gap: '12px' }}>
                <TextField required label="Name" onChange={(e) => setName(e.target.value) } />
                <DatePicker required label="Start date" onChange={(e) => setStartDate(e)} />
                <DatePicker required label="End date" onChange={(e) => setEndDate(e)} />                
                <TextField 
                    required 
                    type="text" 
                    variant="outlined" 
                    label="Feedback questoins"
                    value={questionsString.replace(/(\\n)/g, "\n")}
                    onChange={(e) => setQuestionsString(e.target.value)}
                    multiline
                    minRows={5}
                />
                <FormControlLabel control={
                    <Checkbox size="small" checked={canRegister} onChange={(e) => setCanRegister(e.target.checked)} />
                } label="Enable registering" />
                <FormControlLabel control={
                    <Checkbox checked={canScheduleInterview} onChange={
                        (e) => setCanScheduleInterview(e.target.checked)
                    } />
                } label="Can schedule interview" />
            </FormControl>
        </FormModal>
    );
}

export default NewPhaseForm;