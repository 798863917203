import { useContext, useState } from "react";
import { siteContext } from "../Utils/SiteContext";
import { Button, Divider, FormControl, TextField, Typography } from "@mui/material";
import { AnnouncementsApi } from "../../Utils";
import "./PostAnnouncement.css";


function PostAnnouncement() {
    const { user, successToast, errorToast } = useContext(siteContext);
    const [ announcement, setAnnouncement ] = useState('');

    if(user.rank !== 'admin') {
        return <></>;
    }

    const handleSubmit = async () => {
        const req = await AnnouncementsApi.postNew(user, announcement);
        if(req.status === 200) {
            successToast('Announcement posted!');
        } else {
            errorToast('Failed to post announcement.');
            console.error(await req.text());
        }

        setAnnouncement('');
    }

    return (
        <div id="post-announcement">
            <Typography variant="h5">Post an announcement</Typography>
            <FormControl sx={{ m: 1, width: 300, gap: '16px' }}>
                <TextField
                    required
                    type="text" 
                    variant="outlined" 
                    label="Message"
                    value={announcement.replace(/(\\n)/g, "\n")}
                    onChange={(e) => setAnnouncement(e.target.value)}
                    multiline
                    minRows={10}
                />
                <Button variant="contained" onClick={handleSubmit}>Post</Button>
            </FormControl>
        </div>

    );
}

export default PostAnnouncement;
