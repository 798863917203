import { useContext, useEffect, useState } from "react";
import { BACKEND_URL, userContext } from "../Context";
import Navbar from "../components/Navigation/Navbar";
import { Button, Paper } from "@mui/material";
import "./Interviews.css";
import NewInterviewForm from "../components/Forms/NewInterviewForm";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Delete, RemoveRedEye } from "@mui/icons-material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { TeamsApi, UserApi } from "../Utils";
import ConfirmModal from "../components/ConfirmModal";
import ResultToast from "../components/ResultToast";
import GenerateTeamsForm from "../components/Forms/GenerateTeamsForm";
import { siteContext } from "../components/Utils/SiteContext";

function Teams() {
    const { user } = useContext(siteContext );
    const navigate = useNavigate();

    const [ teams, setTeams ] = useState([]);
    const [ isCreating, setIsCreating ] = useState(false);

    const loadTeams = async () => {
        try {
            const list = await TeamsApi.getAllTeams();

            for(const team of list) {
                if(team.firstMentor) {
                    const firstMentor = await UserApi.getUser(team.firstMentor);
                    team.firstMentor = `${firstMentor.firstName} ${firstMentor.lastName}`;
                } else {
                    team.firstMentor = `No-one`;
                }

                if(team.secondMentor) {
                    const secondMentor = await UserApi.getUser(team.secondMentor);
                    team.secondMentor = `${secondMentor.firstName} ${secondMentor.lastName}`;
                } else {
                    team.secondMentor = `No-one`;
                }

                if(team.members) {
                    team.size = team.members.length;
                } else {
                    team.size = 0;
                }
            }
            setTeams(list);
        } catch(error) {
            console.error(error.message);
        }
    };

    useEffect(() => { loadTeams(); }, [ isCreating ]);

    return <>
        <Navbar />
        <div id="interviews-page">
            <GenerateTeamsForm active={isCreating} setActive={setIsCreating} />

            <div>
                {
                    (user.rank == 'admin')
                        ? (<Button variant="contained" onClick={() => setIsCreating(true)}>Generate teams</Button>)
                        : null
                }
            </div>

            <Paper sx={{ width: '90%', minHeight: '70vh' }}>
                <DataGrid 
                    sx={{ border: 0 }}
                    columns={[
                        { headerName: 'Team name', field: 'name', headerAlign: 'center', minWidth: 130, flex: 1 },
                        { headerName: 'First mentor', field: 'firstMentor', headerAlign: 'center', minWidth: 130, flex: 1 },
                        { headerName: 'Second mentor', field: 'secondMentor', headerAlign: 'center', minWidth: 130, flex: 1 },
                        { headerName: 'Members', field: 'size', type: 'number', headerAlign: 'center', flex: 1 },
                        { 
                            headerName: 'Actions', 
                            type: 'actions', 
                            field: 'actions',
                            headerAlign: 'center',
                            minWidth: 140,
                            flex: 1,
                            getActions: (params) => [
                                <GridActionsCellItem
                                    icon={<RemoveRedEye />}
                                    label="View participants"
                                    onClick={() => navigate(`/viewTeam?id=${params.id}`)}
                                />
                            ]
                        },
                    ]}
                    rows={teams}
                    initialState={{ pagination: { page: 0, pageSize: 5 } }}
                />
            </Paper>
        </div>
    </>;
}

export default Teams;