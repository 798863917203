import { TextField } from "@mui/material";
import FormModal from "./FormModal";
import { useContext, useEffect, useState } from "react";
import { BACKEND_URL, userContext } from "../../Context";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { siteContext } from "../Utils/SiteContext";
import { InterviewApi } from "../../Utils";

function NewInterviewForm({ active, setActive }) {
    const { successToast, errorToast } = useContext(siteContext);
    const [ date, setDate ] = useState(dayjs());
    const [ slots, setSlots ] = useState(20);

    const createInterview = async () => {
        const req = await InterviewApi.createInterview(date, slots);

        if(req.status === 200) {
            successToast(`Interview scheduled.`);
        }
        else {
            errorToast(`Failed to schedule interview.`);
            console.error(await req.text());
        }

        setActive(false);
    };

    return (<FormModal name="newInterview" title="Schedule interview" active={active} setActive={setActive} onSubmit={createInterview}>
        <DateTimePicker required label="Date and time" onChange={(e) => setDate(e)} />
        <TextField required label="Maximum participants" type="number" onChange={(e) => setSlots(e.target.value) } />
    </FormModal>)
}

export default NewInterviewForm;