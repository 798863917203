import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BACKEND_URL, userContext } from "../../Context";
import "./QuickFeedback.css";
import { FeedbackApi } from "../../Utils";
import { siteContext } from "../Utils/SiteContext";

function QuickFeedback() {
    const { user, successToast, errorToast } = useContext(siteContext);
    const [ users, setUsers ] = useState([]);
    const [ selected, setSelected ] = useState();
    const [ feedback, setFeedback ] = useState('');

    const handleSubmit = async () => {
        const req = await FeedbackApi.createFeedback(null, user, selected, feedback);

        if(req.status == 200) {
            successToast("Feedback posted.");
        }
        else {
            errorToast("Failed to post feedback.");
            console.error(await req.text());
        }

        setSelected(null);
        setFeedback('');
    };

    useEffect(() => {
        const loadRecruits = async () => {
            try {
                const req = await fetch(`${BACKEND_URL}/api/user/recruits`, { credentials: 'include' });
                const list = await req.json();
                setUsers(list);
                setSelected(list[0].id);
            } catch(error) {
                console.error(error.message);
            }
        };

        loadRecruits();
    }, []);

    return (<div className="quick-feedback">
        <Typography variant="h5">Quick feedback</Typography>
        <FormControl sx={{ m: 1, width: 300, gap: '16px' }} >
            <InputLabel id="quick-feedback-search">Recruit</InputLabel>
            <Select
                required
                labelId="quick-feedback-search"
                value={selected}
                onChange={(e) => setSelected(e.target.value)}
                input={<OutlinedInput label="Recruit" />}
                MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } }}}
            >
                {
                    users.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                            {user.firstName} {user.lastName}
                        </MenuItem>
                    ))
                }
            </Select>

            <TextField 
                required 
                type="text" 
                variant="outlined" 
                label="Feedback"
                value={feedback.replace(/(\\n)/g, "\n")}
                onChange={(e) => setFeedback(e.target.value)}
                multiline
                minRows={10}
            />

            <Button variant="contained" onClick={handleSubmit}>Submit</Button>
        </FormControl>
    </div>);
}

export default QuickFeedback;
