import { Button, Typography } from "@mui/material";
import Navbar from "../../components/Navigation/Navbar";
import CrocoWorker from "../../assets/croco-worker.jpg";
import { useNavigate } from "react-router-dom";
import "./InConstruction.css";

function InConstruction() {
    const navigate = useNavigate();

    return <>
        <Navbar />
        <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center', 
            gap: '24px'
        }}>
            <img id="bad-request-image" src={CrocoWorker} style={{ marginTop: '32px' }} />
            <Typography variant="h5">
                Croco lucreaza din greu sa termine aceasta pagina. 
            </Typography>

            <Button variant="contained" onClick={() => navigate('/')}>Homepage</Button>
        </div>
    </>;
}

export default InConstruction;
