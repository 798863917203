import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import FormModal from "./FormModal";
import { useContext, useState } from "react";
import { BACKEND_URL, userContext } from "../../Context";
import { siteContext } from "../Utils/SiteContext";
import { UserApi } from "../../Utils";

function NewUserForm({ active, setActive }) {
    const { user, successToast, errorToast } = useContext(siteContext);
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ rank, setRank ] = useState('siscot');

    const createUser = async () => {
        // validari de facut
  
        const req = await UserApi.createUser(firstName, lastName, email, rank);
        if(req.status === 200) {
            successToast(`User "${firstName} ${lastName}" created.`);
        }
        else {
            errorToast(`Failed to create user "${firstName} ${lastName}"`);
            console.error(await req.text());
        }

        setActive(false);
    };

    return (
        <FormModal name="newUser" title="Create new user" active={active} setActive={setActive} onSubmit={createUser}>
            <TextField required type="text" variant="outlined" label="First name" onChange={(e) => setFirstName(e.target.value) } />
            <TextField required type="text" variant="outlined" label="Last name" onChange={(e) => setLastName(e.target.value) }/>
            <TextField required type="email" variant="outlined" label="Email address" onChange={(e) => setEmail(e.target.value) } />
            <FormControl>
                <InputLabel id="user-rank">Account type</InputLabel>
                <Select 
                    labelId="user-rank" 
                    label="Account type" 
                    value={rank} 
                    onChange={(e) => setRank(e.target.value) }
                >
                    <MenuItem value='siscot'>Membru SiSC</MenuItem>
                    <MenuItem value='recruit'>Recrut</MenuItem>
                    {
                        (user.rank == 'admin')
                            ? <MenuItem value='admin'>Admin</MenuItem>
                            : null
                    }
                </Select>
            </FormControl>
        </FormModal>
    )
}

export default NewUserForm;