import { useNavigate } from "react-router-dom";
import { Icon, MenuItem, Typography } from "@mui/material";
import "./NavButton.css";

function NavButton({ name, destination, onClick, icon }) {
    const navigate = useNavigate();

    return <>
        <MenuItem key={name} onClick={(e) => { 
            if(destination)
                navigate(destination);

            if(onClick)
                onClick();
        }}>
            <Icon className="navbar-btn-icon" color="inherit">{icon}</Icon>
            <Typography sx={{ textAlign: 'center' }}>{name}</Typography>
        </MenuItem>
    </>;
}

export default NavButton;