import { BACKEND_URL } from "../Context";
import "./Login.css";

function Login() {
    return (
        <div className="login">
            <div className="login-box">
                <h1>Banzai</h1>
                <a href={`${BACKEND_URL}/api/google`}>
                    <button className="login-with-google-btn">
                        Sign in with Google
                    </button>
                </a>
            </div>
        </div>
    )
}

export default Login;