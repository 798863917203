import { useContext, useEffect, useState } from "react";
import FormModal from "./FormModal";
import { Slider, Typography } from "@mui/material";
import { TeamsApi } from "../../Utils";
import { siteContext } from "../Utils/SiteContext";

function GenerateTeamsForm({ active, setActive }) {
    const { successToast, errorToast } = useContext(siteContext);
    const [ firstOptionWeight, setFirstOptionWeight ] = useState(1.4);
    const [ secondOptionWeight, setSecondOptionWeight ] = useState(0.5);
    const [ teamSizeWeight, setTeamSizeWeight ] = useState(0.3);

    const handleSubmit = async () => {
        const res = await TeamsApi.generateNewTeams(firstOptionWeight, secondOptionWeight, teamSizeWeight);
        if(res.status === 200) {
            successToast("Teams generated.");
        } else {
            errorToast("Failed to generate teams.");
            console.error(await res.text());
        }

        setActive(false);
    };

    return (
        <FormModal name="generateTeams" title="Generate teams" active={active} setActive={setActive} onSubmit={handleSubmit}>
            <Typography>
                Generarea echipelor este un proces <strong><u>FOARTE INCET</u></strong> (~ 10-15s), timp in care
                site-ul, cel mai probabil, <strong><u>nu va functiona</u></strong> (si implicit cel de recrutari). 
                Nu dati spam pls 🙏🙏🙏 
            </Typography>

            <Typography id="first-option-weight" gutterBottom>
                Importance of recruit's first option
            </Typography>
            <Slider
                value={firstOptionWeight}
                min={0} max={2} step={0.1}
                onChange={(e, newValue) => setFirstOptionWeight(newValue)}
                valueLabelDisplay="auto"
                aria-labelledby="first-option-weight"
                marks
            />

            <Typography id="second-option-weight" gutterBottom>
                Importance of recruit's second option
            </Typography>
            <Slider
                value={secondOptionWeight}
                min={0} max={2} step={0.1}
                onChange={(e, newValue) => setSecondOptionWeight(newValue)}
                valueLabelDisplay="auto"
                aria-labelledby="second-option-weight"
                marks
            />

            <Typography id="team-size-weight" gutterBottom>
                Importance of team size
            </Typography>
            <Slider
                value={teamSizeWeight}
                min={0} max={2} step={0.1}
                onChange={(e, newValue) => setTeamSizeWeight(newValue)}
                valueLabelDisplay="auto"
                aria-labelledby="team-size-weight"
                marks
            />
        </FormModal>
    );
}

export default GenerateTeamsForm;