import { useContext, useState } from "react";
import Navbar from "../components/Navigation/Navbar";
import Login from "./Login";
import QuickFeedback from "../components/Feedback/QuickFeedback";
import { siteContext } from "../components/Utils/SiteContext";
import PostAnnouncement from "../components/Feedback/PostAnnouncement";
import "./Homepage.css";

function Homepage() {
    const { user } = useContext(siteContext);

    return <>
        <Navbar />
        <div id="homepage">
            <h1>Welcome back, {user.firstName}!</h1>

            <div id="homepage-actions">
                <QuickFeedback />
                <PostAnnouncement />
            </div>
        </div>
    </>;
}

export default Homepage;