import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";

function ConfirmModal({ open, setOpen, message, onConfirm }) {
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className="alert-dialog-title">
                Confirm action?
            </DialogTitle>
            {
                (message)
                    ? (
                        <DialogContent>
                            <DialogContentText className="alert-dialog-description">
                                {message}
                            </DialogContentText>
                        </DialogContent>
                    )
                    : null
            }
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={() => {
                    setOpen(false);

                    if(onConfirm)
                        onConfirm();
                }}>Confirm</Button>
            </DialogActions>
            
        </Dialog>

    );
}

export default ConfirmModal;