import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { InterviewApi, UserApi } from "../../Utils";
import Navbar from "../../components/Navigation/Navbar";
import UsersList from "../../components/UsersList";
import "../Users.css";
import { Typography } from "@mui/material";
import dayjs from "dayjs";

function ViewInterview() {
    const [ searchParams ] = useSearchParams();
    const [ viewedInterview, setViewedInterview ] = useState();
    const [ participants, setParticipants ] = useState([]);

    useEffect(() => {
        const loadInterview = async () => {
            const interview = await InterviewApi.getInterview(searchParams.get('id'));
            setViewedInterview(interview);

            if(!interview.participants)
                return;

            const users = [];
            for(const id of interview.participants) {
                users.push(await UserApi.getUser(id));
            }
            setParticipants(users);
        };

        loadInterview();
    }, []);

    if(!viewedInterview)
        return (<Navbar />);

    return <>
        <Navbar />  
        <div id="users-page">
            <Typography variant="h4">Interview participants</Typography>
            <Typography variant="h5">
                {
                    dayjs(viewedInterview.date)
                        .format("dddd, DD MMMM YYYY (HH:mm)")
                }
            </Typography>
            <UsersList title={`Participants`} list={participants} />
        </div>
    </>;
}

export default ViewInterview;