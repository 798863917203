import { DoNotDisturb } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../Context";
import { useContext } from "react";
import { siteContext } from "../../components/Utils/SiteContext";

function Unauthorized() {
    const { logout } = useContext(siteContext);

    return <>
        <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center',
            width: '100%',
            minHeight: '100vh',
            gap: '24px'
        }}>
            <DoNotDisturb color="error" style={{ fontSize: '128px' }} />
            <Typography variant="h4">You do not have the required permissions to view this page.</Typography>
            <Button variant="contained" onClick={logout}>Logout</Button>
        </div>
    </>
}

export default Unauthorized;