import { Avatar, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../Context";
import { DarkMode, Logout, Person } from "@mui/icons-material";
import { siteContext } from "../Utils/SiteContext";
import NavButton from "./NavButton";

function AvatarButton({ userId }) {
    const context = useContext(siteContext);
    const navigate = useNavigate();
    const [ anchorEl, setAnchorEl ] = useState();

    return <>
        <Menu
            id="account-menu"
            anchorEl={anchorEl}
            open={anchorEl ? true : false}
            onClose={ () => setAnchorEl(null) }
            onClick={ () => setAnchorEl(null) }
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
        >
            <NavButton name="View profile" icon="person" destination={`/viewUser?id=${userId}`} />
            <NavButton 
                name={context.theme == 'light' ? "Dark theme" : "Light theme"}
                icon={context.theme == 'light' ? "dark_mode" : "light_mode"}
                onClick={context.toggleTheme}
            />
            <Divider />
            <NavButton name="Logout" icon="logout" onClick={context.logout} />
        </Menu>
        <Tooltip title="Account settings">
            <IconButton 
                onClick={(e) => setAnchorEl(e.currentTarget)}
                aria-controls={ anchorEl ? 'account-menu' : undefined }
                aria-haspopup="true"
                aria-expanded={ anchorEl ? 'true' : undefined }
            >
                <Avatar id="navbar-avatar" alt="User avatar" src={`${BACKEND_URL}/api/user/${userId}/avatar`} />
            </IconButton>
        </Tooltip>
    </>;
}

export default AvatarButton;