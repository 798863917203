import { useContext, useEffect, useState } from "react";
import { BACKEND_URL, userContext } from "../Context";
import Navbar from "../components/Navigation/Navbar";
import { Button, Paper } from "@mui/material";
import "./Interviews.css";
import NewInterviewForm from "../components/Forms/NewInterviewForm";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Delete, RemoveRedEye } from "@mui/icons-material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { siteContext } from "../components/Utils/SiteContext";

function Interviews() {
    const { user } = useContext(siteContext);
    const navigate = useNavigate();
    const [ interviews, setInterviews ] = useState([]);
    const [ isCreating, setIsCreating ] = useState(false);

    const loadInterviewList = async () => {
        const req = await fetch(`${BACKEND_URL}/api/interview`);
        const list = await req.json();
        const _interviews = list.map((elem) => {
            const date = dayjs(elem.date);
            return {
                id: elem.id,
                date: date.format("dddd, DD MMMM YYYY"),
                time: date.format("HH:mm"),
                freeSlots: elem.freeSlots
            };
        });
        setInterviews(_interviews);
    };

    useEffect(() => { loadInterviewList(); }, []);
    useEffect(() => { loadInterviewList(); }, [ isCreating ]);

    return <>
        <Navbar />
        <div id="interviews-page">
            {
                (isCreating)
                    ? <NewInterviewForm active={isCreating} setActive={setIsCreating} />
                    : null
            }

            {
                (user.rank == 'admin')
                    ? (<div><Button variant="contained" onClick={() => setIsCreating(true)}>Schedule interview</Button></div>)
                    : null
            }

            <Paper sx={{ width: '90%', minHeight: '70vh' }}>
                <DataGrid 
                    sx={{ border: 0 }}
                    columns={[
                        { 
                            headerName: 'Actions', 
                            type: 'actions', 
                            field: 'actions',
                            maxWidth: 100,
                            flex: 1,
                            getActions: (params) => [
                                <GridActionsCellItem
                                    icon={<RemoveRedEye />}
                                    label="View participants"
                                    onClick={() => navigate(`/viewInterview?id=${params.id}`)}
                                />,
                                <GridActionsCellItem
                                    icon={<Delete />}
                                    label="Delete interview"
                                    onClick={() => { 
                                        if(user.rank == 'admin') {
                                            fetch(`${BACKEND_URL}/api/interview/${params.id}`, {
                                                credentials: 'include',
                                                method: 'DELETE'
                                            }).then(() => { loadInterviewList() });
                                        }
                                    }}
                                    showInMenu
                                />
                            ]
                        },
                        { headerName: 'Scheduled date', field: 'date', minWidth: 130, flex: 1 },
                        { headerName: 'Time', field: 'time', minWidth: 130, flex: 1 },
                        { headerName: 'Remaining slots', field: 'freeSlots', minWidth: 130, flex: 1 },
                    ]}
                    rows={interviews}
                    initialState={{ pagination: { page: 0, pageSize: 5 } }}
                />
            </Paper>
        </div>
    </>;
}

export default Interviews;