import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TeamsApi, UserApi } from "../../Utils";
import Navbar from "../../components/Navigation/Navbar";
import "../Users.css";
import { Button, Typography } from "@mui/material";
import UsersList from "../../components/UsersList";

function ViewTeam() {
    const [ searchParams ] = useSearchParams();
    const [ viewedTeam, setViewedTeam ] = useState();
    const [ members, setMembers ] = useState([]);
    const [ mentors, setMentors ] = useState([]);

    useEffect(() => {
        const loadTeam = async () => {
            const team = await TeamsApi.getTeam(searchParams.get('id'));
            setViewedTeam(team);

            if(!team.members)
                return;

            const users = [];
            for(const id of team.members) {
                users.push(await UserApi.getUser(id));
            }

            setMembers(users);

            const _mentors = [];
            if(team.firstMentor) {
                const mentor = await UserApi.getUser(team.firstMentor);
                _mentors.push(`${mentor.firstName} ${mentor.lastName}`);
            }

            if(team.secondMentor) {
                const mentor = await UserApi.getUser(team.secondMentor);
                _mentors.push(`${mentor.firstName} ${mentor.lastName}`);
            }

            setMentors(_mentors);
        };

        loadTeam();
    }, [ searchParams ]);

    if(!viewedTeam)
        return (<Navbar />);

    return <>
        <Navbar />
        <div id="users-page">
            <Typography variant="h4">Viewing team "{viewedTeam.name}"</Typography>
            <Typography variant="h5">
                Mentors: <b>{
                    mentors.length == 0
                        ? 'None'
                        : mentors.map((elem, i) => (
                            (i == mentors.length - 1)
                                ? elem
                                : `${elem}, `
                        ))
                }</b>
            </Typography>
            {
                mentors.length < 2
                    ? (
                        <Button variant="contained" size="small">Assign Mentors</Button>

                    )
                    : null
            }
            <UsersList 
                title="Members" 
                list={members} 
                showDeptOptions
            />
        </div>

    </>
}

export default ViewTeam;
