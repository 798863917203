import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import FormModal from "./FormModal";
import { useContext, useEffect, useState } from "react";
import { BACKEND_URL, userContext } from "../../Context";
import { siteContext } from "../Utils/SiteContext";
import { InterviewApi, UserApi } from "../../Utils";
import dayjs from "dayjs";

function UpdateUserForm({ target, active, setActive }) {
    const { user, successToast, errorToast } = useContext(siteContext);
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ rank, setRank ] = useState();

    const [ interviews, setInterviews ] = useState([]);
    const [ selectedInterview, setSelectedInterview ] = useState();
    const [ token, setToken ] = useState();

    const updateTarget = async () => {
        // validari de facut
        const data = {};
        if(firstName)
            data.firstName = firstName;
        if(lastName)
            data.lastName = lastName;
        if(email)
            data.email = email;
        if(rank)
            data.rank = rank;
        if(selectedInterview)
            data.interviewId = selectedInterview;
        if(token)
            data.token = token;
  
        const req = await UserApi.updateUser(target.id, data);
        if(req.status === 200) {
            successToast(`User "${target.firstName} ${target.lastName}" updated.`);
        }
        else {
            errorToast(`Failed to update user "${target.firstName} ${target.lastName}"`);
            console.error(await req.text());
        }

        setActive(false);
    };

    useEffect(() => {
        const getInterviews = async () => {
            setInterviews(await InterviewApi.getAll());
        };

        getInterviews();
    }, []);

    const selectProgrammedInterview = (
        <FormControl>
            <InputLabel id="user-interview">Programmed interview</InputLabel>
            <Select
                labelId="user-interview"
                label="Programmed interview"
                defaultValue={target?.recruit?.interviewId}
                value={selectedInterview}
                onChange={(e) => setSelectedInterview(e.target.value)}
            >
                {
                    interviews.map((elem) => (
                        <MenuItem key={`interview-sel-${elem.id}`} value={elem.id}>
                            { dayjs(elem.date).format(`LLLL`) }
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );

    return (
        <FormModal name="newUser" title={`Updating "${target.firstName} ${target.lastName}"`} active={active} setActive={setActive} onSubmit={updateTarget}>
            <TextField required type="text" variant="outlined" defaultValue={target.firstName} label="First name" onChange={(e) => setFirstName(e.target.value) } />
            <TextField required type="text" variant="outlined" defaultValue={target.lastName} label="Last name" onChange={(e) => setLastName(e.target.value) }/>
            <TextField required type="email" variant="outlined" defaultValue={target.email} label="Email address" onChange={(e) => setEmail(e.target.value) } />
            <FormControl>
                <InputLabel id="user-rank">Account type</InputLabel>
                <Select 
                    labelId="user-rank" 
                    label="Account type" 
                    defaultValue={target.rank}
                    value={rank} 
                    onChange={(e) => setRank(e.target.value) }
                >
                    <MenuItem value='siscot'>Membru SiSC</MenuItem>
                    <MenuItem value='recruit'>Recrut</MenuItem>
                    {
                        (user.rank == 'admin')
                            ? <MenuItem value='admin'>Admin</MenuItem>
                            : null
                    }
                </Select>
            </FormControl>
            { 
                (target.rank == 'recruit')
                    ? selectProgrammedInterview 
                    : null
            }
            {
                (target.rank == 'recruit')
                    ? (<TextField 
                        type="text" 
                        variant="outlined" 
                        defaultValue={target.token} 
                        label="Token" 
                        onChange={(e) => setToken(e.target.value)} 
                    />)
                    : null
            }
        </FormModal>
    )
}

export default UpdateUserForm;