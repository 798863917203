import { useContext, useEffect, useState } from "react";
import { BACKEND_URL, userContext } from "../Context";
import Navbar from "../components/Navigation/Navbar";
import { Button, Paper } from "@mui/material";
import "./Interviews.css";
import NewInterviewForm from "../components/Forms/NewInterviewForm";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Delete, RemoveRedEye } from "@mui/icons-material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { siteContext } from "../components/Utils/SiteContext";
import { GetLogs } from "../Utils";

function Logs() {
    const { user } = useContext(siteContext);
    const navigate = useNavigate();
    const [ logs, setLogs ] = useState([]);

    const loadLogs = async () => {
        try {
            const res = await GetLogs();
            for(const log of res) {
                log.date = dayjs(log.createdAt).format("dddd, DD MMMM YYYY HH:mm");
                console.log(log);
            }
            setLogs(res);
        } catch(error) {
            console.error(error.message);
        }
    };

    useEffect(() => { loadLogs(); }, []);

    return <>
        <Navbar />
        <div id="interviews-page">
            <Paper sx={{ width: '90%', minHeight: '70vh' }}>
                <DataGrid 
                    sx={{ border: 0 }}
                    columns={[
                        { headerName: 'User', field: 'user', minWidth: 130, flex: 1 },
                        { headerName: 'Log type', field: 'type', minWidth: 130, flex: 1 },
                        { headerName: 'Details', field: 'message', minWidth: 260, flex: 1 },
                        { headerName: 'Date', field: 'date', minWidth: 180, flex: 1 },
                        { 
                            headerName: 'Actions', 
                            type: 'actions', 
                            field: 'actions',
                            maxWidth: 100,
                            flex: 1,
                            getActions: (params) => [
                                <GridActionsCellItem
                                    icon={<RemoveRedEye />}
                                    label="View user"
                                    onClick={() => {
                                        console.log(params);
                                        navigate(`/viewUser?id=${params.row.userId}`)
                                    }}
                                />
                            ]
                        },
                    ]}
                    rows={logs}
                    initialState={{ pagination: { page: 0, pageSize: 5 } }}
                />
            </Paper>
        </div>
    </>;
}

export default Logs;